


































import { Component, Vue, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import { ReserveStudentResponseType } from '@/models/api/reserveStudents'
import ModalReserveEdit from '@/components/organisms/ModalReserveEdit.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    ButtonBase,
    InputWithLabel,
    SelectBase,
    TableBase,
    ModalReserveEdit,
  },
})
export default class StudentReserve extends Vue {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private isProcessing = false
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    {
      text: '生徒',
      href: Vue.prototype.$gdlsCookiesV3.isV3() ? '/teacher/setting/v3/student' : '/teacher/setting/student',
    },
    { text: '生徒予約', active: true },
  ]

  private name = ''
  private studentId = ''

  private schoolOptionDatas: { text: string; value: number }[] = []
  private schoolSelectedData: number | null = null

  private gradeOptionDatas: { text: string; value: number }[] = []
  private gradeSelectedData: number | null = null

  private tableBaseItems: {
    name: string
    school: string
    grade: string
    addReserveDate: string
    suspendReserveDate: string
    deleteReserveDate: string
    delete: { onclick: Function; variable: number; name: string }[]
  }[] = []

  private tableBaseFields = [
    { key: 'name', label: '氏名・ID' },
    { key: 'school', label: '学校' },
    { key: 'grade', label: '学年' },
    { key: 'addReserveDate', label: '登録予約日' },
    { key: 'suspendReserveDate', label: '休止予約日' },
    { key: 'deleteReserveDate', label: '削除予約日' },
    { key: 'delete', label: '削除' },
  ]

  private tableBaseButtons = ['delete']

  @Ref() modalReserveEdit!: ModalReserveEdit

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadGrades()
    await this.loadSchools()
    await this.loadReserveDatas()
    Vue.prototype.$loading.complete()
  }

  private search() {
    Vue.prototype.$loading.start()
    this.loadReserveDatas()
    Vue.prototype.$loading.complete()
  }

  // 学年情報読み込み
  private async loadGrades() {
    Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionDatas = res.data.map((grade: { id: number; name: string; code: string; sortNum: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })
  }

  // 学校情報読み込み
  private async loadSchools() {
    Vue.prototype.$http.httpWithToken.get(`/schools`, { params: { branchId: this.branchId } }).then((res: any) => {
      this.schoolOptionDatas = res.data.schools.map((school: { id: number; name: string }) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    })
  }

  private getSearchParams(): any {
    const params = { branchId: this.branchId }

    // 氏名
    if (this.name) {
      params['name'] = this.name
    }
    // ID
    if (this.studentId) {
      params['studentCode'] = this.studentId
    }
    // 学校
    if (this.schoolSelectedData) {
      params['schoolId'] = this.schoolSelectedData
    }
    // 学年
    if (this.gradeSelectedData) {
      params['gradeId'] = this.gradeSelectedData
    }

    return params
  }

  // 生徒予約情報読み込み
  private async loadReserveDatas() {
    const params = this.getSearchParams()
    await Vue.prototype.$http.httpWithToken.get('/reserve_students', { params: params }).then((res: any) => {
      this.tableBaseItems = res.data.reserveStudents.map((reserveStudent: ReserveStudentResponseType) => {
        return {
          name: `${reserveStudent.name}/${reserveStudent.studentCode}`,
          school: reserveStudent.schoolName,
          grade: reserveStudent.gradeName,
          addReserveDate: reserveStudent.addReserveDate ? reserveStudent.addReserveDate : '-',
          suspendReserveDate: reserveStudent.suspendReserveDate ? reserveStudent.suspendReserveDate : '-',
          deleteReserveDate: reserveStudent.deleteReserveDate ? reserveStudent.deleteReserveDate : '-',
          delete: [{ onclick: this.deleteReserve, variable: reserveStudent.id, name: '削除' }],
        }
      })
    })
  }

  private async showReserveModal() {
    this.modalReserveEdit.showModal()
  }

  private deleteReserve(id: number): void {
    if (this.isProcessing) return

    if (!confirm('削除しますか？')) return

    this.isProcessing = true

    Vue.prototype.$http.httpWithToken
      .delete(`/reserve_students/${id}`)
      .then(() => {
        alert('削除しました。')
      })
      .catch(() => {
        alert('削除に失敗しました。')
      })
      .finally(() => {
        this.search()
        this.isProcessing = false
      })
  }
}
