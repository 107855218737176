






























































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    ModalBase,
    ButtonBase,
    SelectBase,
    InputWithLabel,
    DatePickerIcon,
    CheckboxSquare,
  },
})
export default class ModalReserveEdit extends Mixins(ModalBaseMethod, LocalMoment) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private id: number | null = null
  private isSent = false
  private isKnewtonMaintenanceMode = false

  private name = ''
  private studentCode = ''

  private addReserveDate = ''
  private suspendReserveDate = ''
  private deleteReserveDate = ''

  private isImmediately = false
  private immediatelyDisabled = false
  private immediatelyLabel = '　'

  @Prop()
  schoolOptionDatas!: { text: string; value: number }[]
  private schoolSelectedData: number | null = null

  @Prop()
  private gradeOptionDatas!: { text: string; value: number }[]
  private gradeSelectedData: number | null = null

  private get nameErrors(): string[] {
    const errors: string[] = []
    if (!this.name) {
      return errors
    }
    if (this.name.length > 20) {
      errors.push('氏名は20文字までです')
    }
    return errors
  }

  private get codeErrors(): string[] {
    const errors: string[] = []
    if (!this.studentCode) {
      return errors
    }
    if (this.studentCode.length > 20) {
      errors.push('生徒IDは20文字までです')
    }
    if (!this.studentCode.match(/^[0-9a-zA-Z]*$/)) {
      errors.push('生徒IDは半角英数字で入力してください')
    }
    return errors
  }

  private get dateErrors(): string[] {
    const errors: string[] = []
    if (this.addReserveDate && !this.afterThanToday(new Date(this.addReserveDate))) {
      errors.push('登録予約日は翌日以降の日付を入力してください')
    }
    if (this.suspendReserveDate && !this.afterThanToday(new Date(this.suspendReserveDate))) {
      errors.push('休止予約日は翌日以降の日付を入力してください')
    }
    if (this.deleteReserveDate && !this.afterThanToday(new Date(this.deleteReserveDate))) {
      errors.push('削除予約日は翌日以降の日付を入力してください')
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.name &&
      this.nameErrors.length === 0 &&
      this.studentCode &&
      this.codeErrors.length === 0 &&
      this.schoolSelectedData &&
      this.gradeSelectedData &&
      (this.addReserveDate || this.suspendReserveDate || this.deleteReserveDate || this.isImmediately) &&
      this.dateErrors.length === 0
    return permitted ? 'blue' : 'pointer-events-none'
  }

  private async loadKnewtonMaintenance() {
    await Vue.prototype.$http.httpWithToken.get('/knewtonMaintenances/maintenance').then((res: any) => {
      this.isKnewtonMaintenanceMode = res.data.ongoing
    })
  }

  public async showModal() {
    this.isSent = false
    this.name = ''
    this.studentCode = ''
    this.schoolSelectedData = null
    this.gradeSelectedData = null
    this.addReserveDate = ''
    this.suspendReserveDate = ''
    this.deleteReserveDate = ''
    // メンテナンス中は即時登録不可
    await this.loadKnewtonMaintenance()
    this.immediatelyDisabled = this.isKnewtonMaintenanceMode
    this.show()
  }

  private async saveReserve() {
    if (this.isSent) return

    this.isSent = true
    if (this.isImmediately) {
      // 即時登録チェックが入っている場合は生徒を登録
      this.createStudent()
    } else {
      // それ以外は予約を登録
      this.createReserve()
    }
  }

  // 生徒予約登録
  private async createReserve() {
    Vue.prototype.$http.httpWithToken
      .post(`/reserve_students`, this.sendParams())
      .then(() => {
        alert('登録しました。')
        this.$emit('saved')
        this.hide()
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          if (this.addReserveDate) {
            alert('存在する「ID」の「登録予約」は設定できません。')
          } else {
            alert('「休止予約」「削除予約」を行う場合は存在する「ID」「氏名」を指定してください。')
          }
        } else {
          alert('登録に失敗しました。')
        }
        this.isSent = false
      })
  }

  private sendParams() {
    return {
      branchId: this.branchId,
      name: this.name,
      studentCode: this.studentCode,
      schoolId: this.schoolSelectedData,
      gradeId: this.gradeSelectedData,
      addReserveDate: this.addReserveDate,
      suspendReserveDate: this.suspendReserveDate,
      deleteReserveDate: this.deleteReserveDate,
    }
  }

  // 生徒登録
  private async createStudent() {
    Vue.prototype.$http.httpWithToken
      .post(`/students`, this.sendImmediatelyParams())
      .then(() => {
        alert('登録しました。')
        this.hide()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('生徒コードが既に存在するため登録できません。')
        } else {
          alert('登録に失敗しました。')
        }
        this.isSent = false
      })
  }

  private sendImmediatelyParams() {
    return {
      branchId: this.branchId,
      nickname: this.name,
      code: this.studentCode,
      schoolId: this.schoolSelectedData,
      gradeId: this.gradeSelectedData,
    }
  }

  private onSelectReserveDate(newVal: string, DateType: string) {
    switch (DateType) {
      case 'add':
        this.addReserveDate = newVal
        break
      case 'suspend':
        this.suspendReserveDate = newVal
        break
      case 'delete':
        this.deleteReserveDate = newVal
        break
    }
  }
}
